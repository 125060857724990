<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat>
          <v-toolbar-title>UoM</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="accent" small to="/inventory/new-uom">
            <v-icon left dark>mdi-plus</v-icon> New 
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-data-table :loading="loading" loading-text="Loading... Please wait" :headers="headers" :items="uom" :search="search"></v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    uom: [],
    headers: [
      { text: "Id", value: "id" },
      { text: "UOM Code", value: "UomCode" },
      { text: "UOM Name", value: "UomName" }
    ],
  }),
  methods: {
    getUom() {
      const self = this;
      this.loading = true;
       this.$store
        .dispatch("get", `/uom`)
        .then(res => {
          self.uom = res.ResponseData;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getUom();
  }
};
</script>